import React from 'react'
import './Footer.css'
import Github from '../../assets/github.png'
import Instagram from '../../assets/instagram.png'
import LinkedIn from '../../assets/linkedin.png'
import Logo from '../../assets/logo.png'

const Footer = () => {
  return (
 <div className="Footer-container">
    <hr />
    <div className="footer">
        <div className="social-links">
        <a href='https://github.com/prajaptivishal'><img src={Github} alt="" /></a>
        <a href='https://www.instagram.com/prajapativishall/'><img src={Instagram} alt=""/></a>
        <a href='https://www.linkedin.com/in/vishal-prajapati-6559391a5'><img src={LinkedIn} alt="" /></a>
        </div>
   
    <div className="logo-f">
        <a><img src={Logo} alt="" /></a>
    </div>
 </div>
 <div className="blur blur-f-1"></div>
 <div className="blur blur-f-2"></div>
 </div>
  );
};

export default Footer;